<template>
  <!-- 订单管理 -->
  <div class="body">
    <el-row>
      <el-col :span="24">
        <div style="display: flex; align-items: center; justify-content: space-between">
          <h3>订单管理</h3>
          <div>
            <el-select
              v-model="status"
              size="small"
              placeholder="按服务类型筛选"
              clearable
              style="margin-right: 10px"
            >
              <el-option
                v-for="item in optionsStatus"
                :key="item.id"
                :label="item.module_name"
                :value="item.id"
              ></el-option>
            </el-select>
            <el-select v-model="type" size="small" placeholder="按标签筛选" clearable>
              <el-option
                v-for="item in optionsType"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
            <el-button
              size="small"
              style="background-color: #29303e; margin-left: 10px; color: white"
              @click="searchList"
            >
              查询
            </el-button>
          </div>
        </div>
        <div style="border-bottom: 1px solid #bbb; margin: 20px 0"></div>
        <div>
          <el-table
            :header-cell-style="{ background: '#F2F2F2', textAlign: 'center' }"
            :cell-style="{ textAlign: 'center', cursor: 'pointer' }"
            :data="tableData"
            style="width: 100%"
          >
            <el-table-column prop="combo_name" label="服务类型"></el-table-column>
            <el-table-column prop="menu_duration" label="购买时长"></el-table-column>
            <el-table-column prop="buy_time" label="购买时间"></el-table-column>
            <el-table-column prop="expire_time" label="到期时间"></el-table-column>
            <el-table-column prop="amount_payable" label="应付金额">
              <template slot-scope="scope">￥{{ scope.row.amount_payable }}</template>
            </el-table-column>
            <el-table-column prop="pay_status" label="支付状态">
              <template slot-scope="scope">
                <el-button v-if="scope.row.pay_status == 2" size="mini" type="info" plain>
                  支付失败
                </el-button>
                <el-button v-if="scope.row.pay_status == -1" size="mini" type="warning" plain>
                  待支付
                </el-button>
                <el-button v-if="scope.row.pay_status == 1" size="mini" type="primary" plain>
                  已支付
                </el-button>
              </template>
            </el-table-column>
            <!-- <el-table-column fixed="right" label="操作">
                            <template slot-scope="scope">
                                <el-button @click.stop="enable(scope.row)" type="text" size="small"
                                    @click="scope.row.pay_status == 2 ? '-' : scope.row.pay_status == -1 ? '去支付' : $router.push('/invoice')">
                                    {{ scope.row.pay_status == 2 ? '-' : scope.row.pay_status == -1 ? '去支付' : '去开票' }}
                                </el-button>
                            </template>
                        </el-table-column> -->
          </el-table>
          <div style="text-align: center; margin-top: 25px">
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="page.page"
              :page-sizes="[10, 15, 30, 35]"
              :page-size="page.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="page.totalItems"
            ></el-pagination>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { billIndex } from '../../common/orderManagement';
export default {
  name: 'orderManagement',
  data() {
    return {
      //选中服务类型
      status: '',
      // 选中标签
      type: '',
      // 服务类型
      optionsStatus: [],
      // 标签
      optionsType: [],
      // 分页
      page: {
        page: 1,
        pageSize: 10,
        totalItems: 0,
      },
      // 权限列表
      permissionsAction: {},
      // 数据列表
      tableData: [],
    };
  },
  created() {
    this.init();
  },
  methods: {
    searchList() {
      this.page.page = 1;
      this.init();
    },
    // 分页
    handleSizeChange(size) {
      this.page.pageSize = size;
      this.init();
    },
    handleCurrentChange(page) {
      this.page.page = page;
      this.init();
    },
    init() {
      let dt = {
        service_type: this.status, //支付状态  -1 未支付  1已支付 2支付失败
        pay_status: this.type, //服务类型
        page: this.page.page,
        pagesize: this.page.pageSize,
      };
      billIndex(dt).then((res) => {
        let { code, result } = res;
        if (code == 200) {
          this.tableData = result.bill;
          this.page.totalItems = result.total;
          this.optionsType = result.payStatus;
          this.optionsStatus = result.types;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.body {
  font-size: 14px;
  background-color: white;
  padding: 20px;

  h3 {
    margin: 0;
  }
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #29303e;
}
</style>
